<template>
  <div>
    <span ref="warp" class="warp">
      {{ innerHtml }}
    </span>
    <span class="cursor">|</span>
  </div>
</template>

<script>

export default {
  name: "VueTypewriter",
  props: {
    text: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      innerHtml: '',
      index: 0,
      // 定时器时间差
      interval: 90,
      intervalTimeout: 2000,
      intervalBack: 70,
      intervalText: 90,

      intervalTimer: null, // 字符添加进入
      intervalTimeoutTimer: null, // 字符移除进入
      intervalBackTimer: null, // 字符移除进入
      intervalTextTimer: null, // 字符集轮询
    };
  },
  computed: {
    textArr() {
      const { text } = this
      return text
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.clearTimer(this.intervalTimer)
    this.clearTimer(this.intervalTimeoutTimer)
    this.clearTimer(this.intervalBackTimer)
    this.clearTimer(this.intervalTextTimer)
  },
  methods: {
    clearTimer(timer) {
      if (!timer) return
      clearTimeout(timer)
      clearInterval(timer)
      timer = null
    },
    init() {
      this.innerHtml = ''
      this.index = 0
      this.typewriter()
    },

    typewriter () {
      const vm = this
      let str = vm.textArr[vm.index]
      let progress = 0
      let interval = vm.interval
      const { intervalTimeout, intervalBack, intervalText } = vm
      // 定时轮询内容
      vm.intervalTimer = setInterval(function() {
        // 判断字符内容以及开始字节长度
        var current = str.substr(progress, 1)
        if (current === '<') {
          progress = str.indexOf('>', progress) + 1
        } else {
          progress++
        }
        // 截取字符并且展示出来
        vm.innerHtml =str.substring(0, progress) + (progress < str.length && (progress & 1) ? '_' : '')

        // 如果截取字符数大于等于字符数
        if (progress >= str.length) {
          // 清除定时器并且进行后续操作
          clearInterval(vm.intervalTimer)
          vm.intervalTimer = null

          // 字符展示到头后需要进行延迟展示几秒再进行删除展示后续内容
          vm.intervalTimeoutTimer = setTimeout(() => {

            // 删除字符展示功能
            vm.intervalBackTimer = setInterval(function() {
              var current = str.substr(progress, 1)
              if (current === '<') {
                progress = str.indexOf('>', progress) + 1
              } else {
                progress--
              }
              vm.innerHtml = str.substring(0, progress) + (progress < str.length && (progress & 1) ? '' : '')

              // 如果删减到0
              // console.log(progress)
              if (progress <= 0) {
                // 清除定时器
                clearInterval(vm.intervalBackTimer)
                vm.intervalBackTimer = null
                // 判断当前的文字索引处于第几位，如果处于最后一位从零开始重新计算
                if (vm.index === vm.textArr.length - 1) {
                  vm.index = 0
                } else {
                  vm.index ++
                }

                // 紧接着下一个文字集轮询
                vm.intervalTextTimer = setTimeout(() => {
                  vm.typewriter()
                }, intervalText)
              }
            }, intervalBack)
          }, intervalTimeout)
        }
      }, interval)
    }
  }
};
</script>

<style scoped>
@keyframes Cursor {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cursor {
  color: inherit;
  position: relative;
  font: inherit;
  line-height: inherit;
  animation: Cursor 1s infinite;
}
</style>
