<template>
  <div class="header-container">
    <div class="container container_media">
      <div class="header-container-list">
        <el-tooltip popper-class="text-tooltip" placement="bottom-start">
          <div slot="content" class="tooltip-content">
            拖动LOGO至上方书签栏，收藏PlayList
          </div>
          <div class="play-list" @click="$router.push(HeaderMenuList[0].path)">
            <a href="https://www.playlistmusic.com.cn/playList">
              <img :src="logoUrl" alt="PlayList" :style="$route.path === '/music' ? 'margin-top: -3px;' : ''" />
              <!-- <img src="@/assets/image/mobile/mlogo.png" alt="PlayList" /> -->
            </a>
          </div>
        </el-tooltip>
        <!-- @tab-click="handleTabClick" -->
        <div class="l-play-list">
          <!-- <el-tabs v-if="hideMenuList" v-model="tabName" @tab-click="handleTabClick">
            <el-tab-pane v-for="(item, index) in menuList" :key="index" :name="index + ''">
              <p slot="label" style="position: relative;">{{ item.name }}<img v-if="index === 5" class="hot-label hot-view"
                  src="~@/assets/image/hot.png" alt=""></p>
            </el-tab-pane>
          </el-tabs> -->
          <div v-if="hideMenuList" class="menus-wrap">
            <div class="item" v-for="(item, index) in menuList" :key="`l_${index}`" :class="{'menu-active': index == activeIndex}" @click="handleMenuClick(item.path)">
              {{ item.name }}
              <img v-if="index === 5" class="hot-label hot-view" src="~@/assets/image/hot.png" alt="">
            </div>
          </div>
          <el-tabs v-else v-model="defaultTab" @tab-click="handleSearchTabClick">
            <el-tab-pane v-for="(item, index) in searchPageTag" :key="index" :name="index + ''">
              <p slot="label" style="position: relative;">{{ item.name }}</p>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="s-play-list">
          <div v-if="hideMenuList" class="menus-wrap">
            <div class="item" v-for="(item, index) in sMenuList" :key="`s_${index}`" :class="{'menu-active': index == activeIndex}" @click="handleMenuClick(item.path)">
              {{ item.name }}
            </div>
          </div>
          <el-tabs v-else v-model="defaultTab" @tab-click="handleSearchTabClick">
            <el-tab-pane v-for="(item, index) in searchPageTag" :key="index" :name="index + ''">
              <p slot="label" style="position: relative;">{{ item.name }}</p>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-if="hideMenuList" class="dropdown-wrap"
            @mouseover="showMoreMenu = true"
            @mouseleave="showMoreMenu = false">
          <div class="more-menu">
            <img src="~@/assets/image/guide/more_menu.svg" alt="">
          </div>
          <div class="check-menu" v-if="showMoreIcon || showMoreMenu"></div>
          <div class="content"
              :class="{'content-show': showMoreMenu}"
              @mouseover="showMoreMenu = true"
              @mouseleave="showMoreMenu = false"
            >
            <p>更多服务</p>
            <div
              class="menu-item mb-8"
              @mouseover="mouseOver('aiHover')"
              @mouseleave="mouseLeave('aiHover')"
              @click="handleSwitch('/aiVoice')">
              <img src="~@/assets/image/guide/ai_icon.png" alt="">
              <div>
                <p>AI语音</p>
                <span>在线生成 <img :src="require(`@/assets/image/guide/menu_${aiHover ? 'check' : 'default'}.svg`)" alt=""></span>
              </div>
            </div>
            <div
              class="menu-item mb-8"
              @mouseover="mouseOver('limitHover')"
              @mouseleave="mouseLeave('limitHover')"
              @click="handleSwitch('/limitExempt')">
              <img src="~@/assets/image/guide/limit.png" alt="">
              <div>
                <p>限免专区</p>
                <span>在线生成 <img :src="require(`@/assets/image/guide/menu_${limitHover ? 'check' : 'default'}.svg`)" alt=""></span>
              </div>
            </div>
          </div>
        </div>
        <slot></slot>
      </div>
      <div class="header-container-operation">
        <div class="openVip" @click="handlePackage(1)">
          <span style="white-space: nowrap;margin-left: 12px;">个人VIP</span>
        </div>
        <div class="openVip" @click="handlePackage(2)">
          <img :src="require('@/assets/svg/vip_set_icon.svg')" alt="" class="crown" />
          <span class="compantVip">企业VIP</span>
          <el-carousel height="46px" direction="vertical" :autoplay="true" indicator-position="none">
            <el-carousel-item>正版素材</el-carousel-item>
            <el-carousel-item>商用首选</el-carousel-item>
            <el-carousel-item>正版素材</el-carousel-item>
            <el-carousel-item>商用首选</el-carousel-item>
          </el-carousel>
        </div>
        <!-- 购物车 icon 及 展示购物车条数-->
        <div class="to-supplier" @click="handleGoSupplier" style="white-space: nowrap;">
          <img src="@/assets/image/supplier_icon_black.png" />
          创作
        </div>
        <el-badge :hidden="!(cartCount && cartCount > 0)" :value="cartCount || 0" class="badge">
          <img src="@/assets/image/cart.png" @click="handleRouterCart" />
        </el-badge>
        <el-badge :hidden="!(messageCount && messageCount > 0)" :value="messageCount || 0" class="badge">
          <img src="@/assets/svg/newMessage.svg" @click="handleRouterMessage" />
        </el-badge>

        <HeaderUserInfo ref="HeaderUserInfo" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { HeaderMenuList } from '@/config'
import { getUserId, getToken } from '@/utils/author'
import HeaderUserInfo from '@/components/HeaderUserInfo'

export default {
  name: 'Header',
  components: { HeaderUserInfo },
  data() {
    return {
      aiHover: false,
      limitHover: false,
      showMoreMenu: false,
      HeaderMenuList,
      tabName: '-1',
      defaultTab: '0',
      logoStyle: {},
      margTop: {
        marginTop: '-3px'
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'cartCount', 'hasMember', 'messageCount']),
    menuList() {
      const list = JSON.parse(JSON.stringify(HeaderMenuList))
      list.shift()
      return list
    },
    // 小屏展示的菜单栏数据
    sMenuList() {
      const ids = [1,2,3,5]
      const list = HeaderMenuList.filter(i=>ids.includes(i.id))
      // list.push({id: 21})
      return list
    },
    hideMenuList() {
      return this.$route.name !== 'SearchResult'
    },
    searchPageTag() {
      const list = JSON.parse(JSON.stringify(HeaderMenuList))
      return list.filter(i=>i.type == this.$route.query.type)
    },
    routerPath() {
      return this.$route.path
    },
    logoUrl() {
      const menuItem = this.menuList.filter(
        (i) => i.path === this.routerPath
      )[0]
      if (menuItem && menuItem.logo && menuItem.logo !== '') {
        return menuItem.logo
      }
      return require('@/assets/image/header-logo/home.png')
    },
    activeIndex() {
      let routers = ['/music', '/soundEffect', '/video', '/picture', '/aiVoice', '/limitExempt']
      return routers.indexOf(this.$route.path)
    },
    showMoreIcon() {
      return this.$route.path == '/limitExempt' ||  this.$route.path == '/aiVoice'
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        for (let i = 0; i < this.menuList.length; i++) {
          if (newVal.path === this.menuList[i].path) {
            return (this.tabName = i + '')
          } else {
            this.tabName = '-1'
            continue
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleMoreMenu(){
      // 移入更多菜单栏区域
      this.showMoreMenu = true
    },
    // 移入
    mouseOver(key) {
      this[key] = true;
    },
    // 移出
    mouseLeave(key) {
      this[key] = false;
    },
    handleSwitch(v) {
      this.showMoreMenu = false
      this.$router.push(v)
    },
    /**
     * 切换tab
     */
    handleMenuClick(path){
      this.$router.push(path)
    },
    handleSearchTabClick(tab) {
      // const tabIndex = Number(this.tabName)
      this.$router.push(this.searchPageTag[tab.index].path)
    },
    // 跳转到消息通知
    handleRouterMessage() {
      this.$router.push('/message')
    },
    /**
     * 跳转进入购物车
     * 2021-12-21 15:11:56
     * @author SnowRock
     */
    handleRouterCart() {
      this.$router.push('/cart')
    },
    /**
     * 进入订阅会员
     * 2021-12-21 15:11:56
     * @author SnowRock
     */
    handlePackage(type) {
      this.$router.push(`/package?type=${type}`)
    },
    handleGoSupplier() {
      const userId = getUserId()
      if (!getToken() || !userId) {
        this.$store.dispatch('user/openLogin')
        return
      }
      // 线上环境地址：https://s.playlistmusic.com.cn
      // 测试环境地址： https://t_tests.playlistmusic.com.cn
      let url =
        process.env.NODE_ENV === 'production'
          ? 'https://s.playlistmusic.com.cn'
          : 'https://t_tests.playlistmusic.com.cn'
      //  window.open(`http://localhost:8083/creatorCenter?userId=${userId}`)
      window.open(`${url}?userId=${userId}`, getToken())
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes checkIcon {
  0% {
    left: -60px;
  }
  100% {
    left: 0;
  }
}
.s-play-list .menus-wrap {
  width: 300px;
}
.l-play-list .menus-wrap {
  width: 530px;
}
.menus-wrap {

  display: flex;
  div.item {
    position: relative;
    margin: 0 24px;
    font-size: 16px;
    color: #000000;
    height: 62px;
    line-height: 62px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
      bottom: 2px;
      left: 0px;
    }
    &:first-child{
      margin-left: 0px;
    }
    &:hover{
      &::after {
        background: #E52A0D;
      }
    }
  }
  .menu-active.item {
    font-weight: 500;
    color: #E52A0D;
    &::after {
      background: #E52A0D;
    }
  }
}
.dropdown-wrap {
  margin-right: 24px;
  position: relative;
  .check-menu {
    position: absolute;
    width: 32px;
    height: 4px;
    background: #E52A0D;
    bottom: 2px;
    left: 16px;
    // animation: checkIcon .2s;
  }
  .more-menu {
    padding: 0 24px;
    height: 62px;
    display: flex;
    align-items: center;
  }
  .content {
    position: absolute;
    top: 60px;
    left: -92px;
    width: 200px;
    height: 190px;
    background: url('~@/assets/image/guide/more_bj.png') no-repeat;
    background-size: 100% 100%;
    padding: 16px 0 20px;

    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    border-radius: 0px 0px 8px 8px;
    display: none;
    p{
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      margin-bottom: 7px;
    }
    .menu-item {
      cursor: pointer;
      width: 168px;
      height: 56px;
      background: #FFFFFF;
      box-shadow: 0px 0px 7px 0px rgba(229,42,13,0.1);
      border-radius: 12px;
      display: flex;
      padding-left: 20px;
      align-items: center;
      img{
        width: 38px;
        height: 38px;
        margin-right: 16px;
      }
      div {
        p{
          font-size: 14px;
          color: #333333;
          margin-bottom: 2px;
        }
        span {
          font-size: 12px;
          color: #999999;
          img{
            width: 12px;
            height: 12px;
            margin-left: 6px;
            position: relative;
            top: 1.5px;
            transition: margin-left 0.3s;
          }
        }
      }
    }
    .menu-item:hover {
      div span {
        color: rgba(255,142,20,0.85);
        img {
          margin-left: 12px;
          transition: margin-left 0.3s;
        }
      }
    }
    .mb-8 {
      margin-bottom: 8px;
    }
  }
  .content-show {
    display: flex;
  }
}
.header-container {
  @include size(100%, 60px);
  // overflow: hidden;
  z-index: 2000;
  min-width: 1000px;
  position: fixed;
  left: 0;
  top: 0;
  // padding: 0 $padding-lr;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  // height: 100%;
  width: 100%;
  .container{
    @include center-middle-flex($justify: space-between);
    height: 100%;
    // width: 1410px;
    // margin: 0 auto;
  }

  // .container_media {
  //   @include center-middle-flex($justify: space-between);
  //   height: 100%;
  // }

  &-list {
    @include center-middle-flex;
    cursor: pointer;
  }
  .play-list {
    font-size: 18px;
    margin-right: 47px;

    img {
      width: 118px;
      height: auto;
      margin-top: 6px;

      &[src='https://qkodo.playlistmusic.com.cn/assets/common/202201/music_logo.png'] {
        width: 97px;
        margin-right: -2px;
        transform: translate(-3px, -2px);
      }
    }
  }

  /deep/ .el-tabs__active-bar {
    height: 4px;
    bottom: 5px;
  }

  /deep/ .el-tabs__item {
    font-size: 16px;
    // padding-left: 0;
    padding: 0 23.5px;
    // padding-right: 47px;
    height: 84px;
    line-height: 98px;
    box-sizing: border-box;

    &:focus {
      box-shadow: none;
    }
  }

  /deep/ .el-tabs__item:not(.is-active) {
    color: #000;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }

  /deep/ .el-tabs__item:nth-last-of-type(1) {
    margin-right: 36px;
  }

  /deep/ .el-tabs__nav-wrap::after {
    background: none;
  }

  &-operation {
    @include center-middle-flex;
    font-size: $font-size-sl;
    line-height: 22px;
    cursor: pointer;

    .dyhy {
      width: 120px;
      height: 40px;
      background: url('../assets/image/dyhy_btn.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 38px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 17px;
      font-weight: normal;
      color: #ffffff;
      text-shadow: 1px 1px 0px #bb0000;
      margin-right: 25px;
    }

    .to-supplier {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;

      img {
        margin-right: 6px;
        @include square(16px);
      }
    }

    div {
      margin-right: 26px;
    }

    div:nth-last-of-type(1) {
      margin-right: 0;
    }

    .badge {
      @include center-middle-flex;

      /deep/ .el-badge__content {
        background: $theme-color;
        border: none;
      }

      img {
        @include square(16px);
      }
    }
  }
}

.header-container-operation {
  .openVip {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    position: relative;
    .el-carousel--vertical{
      position: absolute;
      left: 50%;
      top: -14px;
      transform: translateX(-50%);
      width: 60px;
      height: 16px;
      background-color: #E52A0D;
      color: #fff;
      font-weight: bold;
      border-radius: 8px;
      font-size: 12px;
      .el-carousel__item{
        text-align: center;
        line-height: 16px;
      }
    }
    .crown {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .compantVip{
    white-space: nowrap;
    background: linear-gradient(135deg, #F9CF7E 0%, #F5C164 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.hot-view {
  width: 28px;
  position: absolute;
  bottom: 38px;
  right: -30px;
  animation: hot 1.4s ease-in infinite;
}

@keyframes hot {
  0% {
    transform: scale(1) rotateZ(0deg);
    transform-origin: 0% 100%;
  }

  20% {
    transform: scale(0.8);
    transform-origin: 0% 100%;
  }

  35% {
    transform: scale(0.8) rotateZ(-20deg);
    transform-origin: 0% 100%;
  }

  50% {
    transform: scale(0.9) rotateZ(8deg);
    transform-origin: 0% 100%;
  }

  60% {
    transform: scale(1) rotateZ(0deg);
    transform-origin: 0% 100%;
  }
}</style>
