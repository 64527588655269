var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-container",style:(_vm.isShowHeaderOrFooter?'padding-bottom: 345px;':'')},[_c('Header',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHeaderOrFooter),expression:"isShowHeaderOrFooter"}]},[(
        _vm.$route.name === 'soundEffect' ||
        _vm.$route.name === 'search' ||
        _vm.$route.name === 'SearchResult'
      )?_c('Search',{attrs:{"mode":"nav","box-style":_vm.search.boxStyle,"btn-style":_vm.search.btnStyle,"placeholder":_vm.search.placeholder},on:{"search":_vm.handleSearch}}):_vm._e(),(
        _vm.$route.path === '/playList'
      )?[_c('VueTypewriter',{staticClass:"home-nav-tips",attrs:{"text":_vm.words}})]:_vm._e()],2),_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e(),_c('Footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHeaderOrFooter),expression:"isShowHeaderOrFooter"}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }