<template>
  <div>
    <div v-if="!userInfo" class="login">
      <img src="../assets/image/mrgx.png" alt="" class="hot-mrgx" v-if="!userInfo">
      <!-- <img src="../assets/image/hot_login.png" alt="" class="hot-login" v-if="!userInfo"> -->
      <span @click="handleLogin">登录</span>
      <span class="login-mdidd">/</span>
      <span @click="handleRegister">注册</span>
    </div>
    <div class="user-info-popper" v-else>
      <el-popover popper-class="user-popper-container" :append-to-body="false" trigger="hover">
        <template slot="reference">
          <img class="user-avatar" src="https://qkodo.playlistmusic.com.cn/default%2Fdefault-avatr-2022061011.png"
            @click="$router.push({ path: '/member/center' })" /></template>
        <div slot="default" class="user-member-info-container">
          <div class="user-info">
            <div class="user-avatar">
              <img src="https://qkodo.playlistmusic.com.cn/default%2Fdefault-avatr-2022061011.png" />
            </div>
            <div class="user-name">
              <p>{{ userInfo.name }}</p>
              <p class="user-id">账号:{{ userInfo.phone ? userInfo.phone : userInfo.email }}</p>
            </div>
            <div class="user-login-out" @click="handleLoginOut">退出</div>
          </div>
          <div class="user-member">
            <div class="user-member-type people">
              <div class="icon">
                <el-image :src="VipIcon" />
              </div>
              <div class="name">个人会员</div>
              <div class="button" @click="handlePackage(1)">
                {{
                  userInfo.vip_info.vip_type.includes(1)
                  ? '立即续费'
                  : '立即开通'
                }}
              </div>
            </div>
            <div class="user-member-type company">
              <div class="icon">
                <el-image :src="VipIcon" />
              </div>
              <div class="name">企业会员</div>
              <div class="button" @click="handlePackage(2)">
                {{
                  userInfo.vip_info.vip_type.includes(2)
                  ? '立即续费'
                  : '立即开通'
                }}
              </div>
            </div>
          </div>
          <ul class="user-center-list">
            <li v-for="(i, j) in memberMenus" :key="`menu-li-${j}`" :class="{
              active: active === i.eventName
            }" @click="handleMenuClick(i)" class="menu-item">
              <p class="icon"><img :src="i.icon" alt="" /></p>
              <p class="label">{{ i.name }}</p>
            </li>
          </ul>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { memberMenus } from '@/config'
import VipIcon from '@/assets/svg/vip_icon.svg'
import CVipIcon from '@/assets/svg/vip_icon.svg'
import Buttons from '@/layout/mixins/Buttons'
import store from '@/store'
import { send_eventApi } from '@/utils/common.js'

export default {
  name: 'HeaderUserInfo',
  data() {
    return {
      VipIcon,
      CVipIcon,
      memberMenus: memberMenus,
      active: ''
    }
  },
  mixins: [Buttons],
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    $route: {
      handler(newVal) {
        const info = this.memberMenus.filter((i) => i.path === newVal.path)[0]
        const hrefInfo = this.memberMenus.filter(
          (i) => i.path === window.location.href
        )[0]

        if (info) {
          this.active = info.eventName
        }
        if (hrefInfo) {
          this.active = hrefInfo.eventName
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * 进入订阅会员
     * 2021-12-21 15:11:56
     * @author SnowRock
     */
    handlePackage(type) {
      const obj = {
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_recharge', obj)
      this.$router.push({
        path: '/package',
        query: {
          type: type
        }
      })
    },
    /**
     * 跳转登录弹框
     * 2021-12-16 16:48:01
     * @author SnowRock
     */
    handleLogin() {
      this.$store.dispatch('user/openLogin')
    },
    /**
     * 跳转会员中心
     * 2021-12-20 11:19:14
     * @author SnowRock
     */
    handleMemberCenter() {
      this.$router.push('/member')
    },
    /**
     * 退出登录
     * 2021-12-24 15:16:10
     * @author SnowRock
     */
    handleLoginOut() {
      store.dispatch('user/loginOut')
      store.dispatch('cart/updateList', [])
      // 清除登录用户id
      window.gio('clearUserId');
    },
    /**
     * 跳转注册弹框（同样是登录弹框）
     * 2021-12-16 16:48:01
     * @author SnowRock
     */
    handleRegister() {
      this.$store.dispatch('user/openRegister')
    }
  }
}
</script>
<style lang="scss">
.inline-block-al-center {
  display: inline-block;
  vertical-align: middle;
}

.user-popper-container {
  &.el-popover {
    padding: 0;
    top: 62px !important;
  }

  .user-member-info-container {
    width: 260px;
    background: #ffffff;
    border-radius: 2px;
    padding: 12px;

    .user-info {
      margin-bottom: 12px;

      .user-avatar,
      .user-name,
      .user-login-out {
        @extend .inline-block-al-center;
      }

      .user-name {
        width: calc(100% - 104px);
        margin-right: 8px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;

        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .user-id {
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.48);
          line-height: 14px;
        }
      }

      .user-avatar {
        width: 40px;
        height: 40px;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .user-login-out {
        width: 48px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.64);
        color: rgba(0, 0, 0, 0.64);
        line-height: 18px;
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        cursor: pointer;
      }
    }

    .user-member {
      position: relative;
      padding-bottom: 1px;

      &:after {
        content: '';
        width: 249px;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
      }

      .user-member-type {
        height: 32px;
        line-height: 32px;
        border-radius: 5px;
        margin-bottom: 8px;
        padding-left: 12px;
        padding-right: 8px;

        .icon,
        .name,
        .button {
          @extend .inline-block-al-center;
        }

        .icon {
          width: 16px;
          height: 16px;
          line-height: 16px;
          margin-right: 4px;
        }

        .button {
          width: 60px;
          height: 20px;
          line-height: 20px;
          border-radius: 10px;
          font-size: 10px;
          text-align: center;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          cursor: pointer;

          &.disabled {
            background: #999999 !important;
            color: #ffffff !important;
          }
        }

        .name {
          width: calc(100% - 84px);
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          margin-right: 4px;
        }

        &.people {
          background: #fae6c7;

          .name {
            color: rgba(0, 0, 0, 0.64);
          }

          .button {
            color: #fff;
            background: linear-gradient(135deg,
                #feddac 0%,
                #feddac 0%,
                #ffc160 100%);
          }
        }

        &.company {
          background: linear-gradient(135deg, #434352 0%, #171921 100%);
          box-shadow: 0px -4px 7px 0px rgba(33, 48, 83, 0.07),
            0px 7px 7px 0px rgba(33, 48, 83, 0.07);

          .name {
            color: #ffffff;
          }

          .button {
            background: linear-gradient(135deg,
                #feddac 0%,
                #f2d7b0 1%,
                #fae6c7 100%);
            color: rgba(0, 0, 0, 0.76);
          }
        }
      }
    }

    .user-center-list {
      .menu-item {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        width: 48px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.64);
        line-height: 17px;
        padding-top: 11px;

        .icon {
          margin-bottom: 4px;
          height: 18px;
          line-height: 18px;

          img {
            width: 20px;
            height: 18px;
          }
        }

        &+.menu-item {
          margin-left: 14px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
/deep/ {
  .user-avatar-container {
    &.el-menu.el-menu--horizontal {
      border: none;
      background-color: transparent;

      &>.el-submenu {
        .el-submenu__title {
          height: 70px;
          line-height: 70px;
        }

        .el-submenu__title:hover {
          border: none;
          background-color: transparent;
        }

        .el-submenu__icon-arrow.el-icon-arrow-down {
          display: none;
        }
      }
    }
  }
}

.login {
  white-space: nowrap;
  padding: 6px 20px;
  @include center-middle-flex;
  @include font-heavy($size: 16px, $color: #000000);
  border-radius: 45px;
  position: relative;
  border-radius: 20px;
  border: 1px solid #000000;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);

  .hot-login {
    width: 72px;
    height: 20px;
    position: absolute;
    top: -12px;
    left: 40px;
  }

  .hot-mrgx {
    width: 54px;
    // height: auto;
    position: absolute;
    top: -9px;
    right: 0px;
  }
}

.login-mdidd {
  width: 0.6em;
  margin-left: 0.1em;
}

// 用户头像
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
