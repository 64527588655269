<template>
  <div class="index-container" :style="isShowHeaderOrFooter?'padding-bottom: 345px;':''">
    <Header v-show="isShowHeaderOrFooter">
      <Search
        v-if="
          $route.name === 'soundEffect' ||
          $route.name === 'search' ||
          $route.name === 'SearchResult'
        "
        mode="nav"
        :box-style="search.boxStyle"
        :btn-style="search.btnStyle"
        :placeholder="search.placeholder"
        @search="handleSearch"
      />
      <template v-if="
          $route.path === '/playList'
        ">
        <VueTypewriter
          class="home-nav-tips"
          :text="words"
        />
      </template>
    </Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer v-show="isShowHeaderOrFooter" />
  </div>
</template>

<script>
import Header from '@/components/Header'
// import Header from '@/components/newHeader'
import Search from '@/components/Search'
import Footer from '@/components/newFooter'
import { HeaderMenuList } from '@/config'
import Buttons from '@/layout/mixins/Buttons'
import VueTypewriter from '@/components/VueTypewriter'
const soundEffectSearch = {
  boxStyle: { height: '32px' },
  btnStyle: { width: '80px' },
  placeholder: HeaderMenuList[2].searchPlaceholder
}
const searchPageBox = {
  boxStyle: { width: '500px', height: '36px' },
  btnStyle: { width: '90px' },
  placeholder: HeaderMenuList[3].searchPlaceholder
}

export default {
  components: { VueTypewriter, Header, Search, Footer },
  data() {
    return {
      search: soundEffectSearch,
      isShowHeaderOrFooter: true,
      words: ['素材每日持续更新，每周更新500+', '正版授权，100%正品保障']
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        const { type = 2 } = newVal.query
        if (newVal.path === '/soundEffect') {
          this.search = soundEffectSearch
        }
        if (newVal.path === '/search-result') {
          this.search = {
            ...searchPageBox,
            placeholder: HeaderMenuList[type - 1].searchPlaceholder
          }
        }

        // 判断是否为手机端 是隐藏导航跟页脚
        const ua = navigator.userAgent
        if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
          this.isShowHeaderOrFooter = false
        } else {
          this.isShowHeaderOrFooter = true
        }
        // if(newVal.path == '/activity/activeMobile' || newVal.path == '/activity/shareMobile'){
        //   this.isShowHeaderOrFooter = false;
        // }else{
        //   this.isShowHeaderOrFooter = true;
        // }
      },
      immediate: true
    }
  },
  mixins: [Buttons],
  methods: {}
}
</script>

<style lang="scss" scoped>
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-300px);
  }
}
.index-container {
  position: relative;
}
.home-nav-tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  width: 260px;
  white-space: initial;

  /deep/ {
    .warp {
      background: linear-gradient(135deg, #ff5c22 0%, #E52A0D 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .cursor {
      background: linear-gradient(135deg, #ff5c22 0%, #E52A0D 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
