<template>
  <div class="vf-footer">
    <div class="vf-footer-main container_media">
      <div class="vf-footer-main-title">
        <img class="logo" src="@/assets/image/mobile/mlogo.png" alt="PlayList" />
        <p class="sub-title">视频工作者素材平台</p>
        <span>
          北京数音未来科技有限公司
          <a :href="CompanyIntroduction" target="_blank" title="北京数音未来科技有限公司"></a>
        </span>
      </div>
      <div class="vf-footer-detail">
        <section class="section1">
          <p class="model-title">服务中心</p>
          <ul class="serve-list">
            <div>
              <li>
                <a class="link" href="/about" title="公司介绍">公司介绍</a>
              </li>
              <li v-for="(m, i) in serveList" :key="i">
                <a class="link" :href="m.url" target="_blank" :title="m.label">{{ m.label }}</a>
              </li>
            </div>
            <div>
              <li v-for="(m, i) in serveList_2" :key="i">
                <a class="link" :href="m.url" target="_blank" :title="m.label">{{ m.label }}</a>
              </li>
            </div>
          </ul>
        </section>
        <section class="call-wrap">
          <p class="model-title">联系我们</p>
          <p class="phone-number link">
            <img src="~@/assets/image/phone-footer.png" alt="" /><span>135-2235-2793</span>
          </p>
          <p class="tit link">
            <span>商务合作：<br />bd@playlistmusic.com.cn</span>
          </p>
          <p class="tit link">
            <span>联系客服：<br />kefu@playlistmusic.com.cn</span>
          </p>
        </section>
        <section class="footer-wrap">
          <div class="vf-footer-gzh">
            <img src="@/assets/image/gzh.jpeg" alt="" />
            <span>官方公众号</span>
          </div>
          <div class="vf-footer-gzh">
            <img src="@/assets/image/gfwb.png" alt="" />
            <span>官网微博</span>
          </div>
        </section>
      </div>
    </div>
    <div class="vf-footer-bottom">
      <p class="container_media">
        <!-- <span><a href="javascript: void(0)">北京数音未来科技有限公司</a></span> -->
        <span>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备案2021039208号-1</a>
        </span>
        <span style="display: inline-block; align-items: center">
          <a href="http://www.beian.gov.cn/" target="_blank">京公网安备 11010502048788号</a>
        </span>
        <span><a :href="MCT_GOV" target="_blank" title="全国文化举报平台">全国文化举报平台</a></span>
        <span><a :href="PDF_NETWORK_CULTURE_MANAGEMENT_LICENSE" target="_blank"
            title="网络文化经营许可证 京网文（2022）2956-085号">网络文化经营许可证 京网文（2022）2956-085号</a></span>
        <span>
          <a :href="businessCertificate" target="_blank">
            增值电信业务经营许可证 京B2-20214731
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import {
  businessCertificate,
  userProtocol,
  CompanyIntroduction,
  InfringementComplaint,
  PrivacyProtocol,
  InfringementComplaintProtocol,
  UserDisputeSettlement,
  MCT_GOV,
  PDF_NETWORK_CULTURE_MANAGEMENT_LICENSE
} from '@/config'

export default {
  name: 'Footer',
  data() {
    return {
      businessCertificate,
      CompanyIntroduction,
      UserDisputeSettlement,
      MCT_GOV,
      PDF_NETWORK_CULTURE_MANAGEMENT_LICENSE,
      serveList: [{
        label: '侵权申诉指引',
        url: InfringementComplaintProtocol
      }, {
        label: 'PlayList用户协议',
        url: userProtocol
      }],
      serveList_2: [{
        label: '侵权投诉通知书',
        url: InfringementComplaint
      }, {
        label: 'PlayList隐私协议',
        url: PrivacyProtocol
      }, {
        label: '用户纠纷处理',
        url: UserDisputeSettlement
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f7f7f7;
  border-top: 1px solid #E8E8E8;
  font-family: PingFangSC-Semibold, PingFang SC;

  .vf-footer-main {
    padding: 60px 0 34px;
    display: flex;
    width: 100%;

    .vf-footer-main-title {
      margin-right: 15%;

      .logo {
        height: 23.21px;
      }

      .sub-title {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin-top: 13px;
        white-space: nowrap;
      }

      span {
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
        white-space: nowrap;
      }
    }

    .vf-footer-detail {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .section1 {
        width: 25%;

        .serve-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      .link {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        display: inline-block;
        margin-bottom: 12px;
      }

      .model-title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 25px;
        margin-bottom: 25px;
      }

      .call-wrap {
        display: flex;
        flex-direction: column;

        .phone-number {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin-bottom: 19px;

          img {
            @include size(18px, 18px);
            margin-right: 12px;
          }
        }

        .tit {
          font-size: 16px;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .footer-wrap {
        display: flex;
        padding-top: 44px;

        .vf-footer-gzh {
          width: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin-right: 56px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100px;
          }

          span {
            font-size: 14px;
            display: block;
            padding-top: 5px;
          }
        }
      }

    }
  }

  .vf-footer-bottom {
    padding: 12px 0;
    border-top: 1px solid #E8E8E8;

    p {
      width: 100%;
      display: flex;
      overflow: hidden;
      white-space: nowrap;

      span {
        margin-right: 2%;

        &:last-child {
          margin-right: 0;
        }

        a {
          @include font-heavy($color: #999999, $size: 13px);
          line-height: 20px;
        }
      }
    }
  }
}
</style>
